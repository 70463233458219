import phone from "../assets/images/phone.svg"
import mail from "../assets/images/mail.svg"
import location from "../assets/images/location.svg"
import logo from "../assets/images/LumenLogo.svg"
import insta from "../assets/images/insta.svg"
import fb from "../assets/images/fb.svg"
import { Link } from "react-router-dom"
import { useEffect } from "react"
export function Navbar(params) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const handleClickScroll = () => {
        setTimeout(() => {
            
            const element = document.getElementById('service');
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
      };
    return(
        <header>
        <div className="smNavbar">
        <div className="container">
            <div className="mainContent">
            <div className="linkDiv"><img src={phone} alt="phone" /> <a href="tel:978-955-0086">978-955-0086</a></div>
            <div className="linkDiv"><img src={mail} alt="mail" /> <a href="mailTo:contact@lumenspaandlaser.com" > contact@lumenspaandlaser.com </a></div>
            <div className="linkDiv"><img src={location} alt="location" /> <a href="https://goo.gl/maps/7EyzjqraAMYGomGs6" target="_blank">2297 Main St. C Tewksbury, MA 01876 </a></div>
            </div>
        </div>
        </div>
        <nav className="navbar navbar-expand-lg ">
        <div className="container">
            <Link className="navbar-brand" to="/">
            <img className="img-fluid" src={logo} alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="far fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/" onClick={handleClickScroll}>Services</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact Us</Link>
                </li>
            </ul>
            <div className="navSocial">
                <a href="https://instagram.com/lumen_spa?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100085562977140" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="" />
                </a>
            </div>
            </div>
        </div>
        </nav>
    </header>
    )
}