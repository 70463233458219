import {animate} from "../assets/js/animate"

import footerLogo from "../assets/images/footerLogo.svg"
import footerFb from "../assets/images/footerFb.svg"
import footerInsta from "../assets/images/footerInsta.svg"

import footerLocation from "../assets/images/footerLocation.svg"
import FooterPhone from "../assets/images/FooterPhone.svg"
import footerMail from "../assets/images/footerMail.svg"
import whatsapp from "../assets/images/wahtsapp.svg"
import { Link } from "react-router-dom"
import { useEffect } from "react"

export function Footer(params) {
    const handleClickScroll = () => {
        setTimeout(() => {
            
            const element = document.getElementById('service');
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: 'smooth' });
            }
            let serviceCard= document.getElementsByClassName("flipInY")
            for (let i = 0; i < serviceCard.length; i++) {
                serviceCard[i].classList.add("animated")                
            }
          
        }, 10);
      };
      useEffect(()=> {
        animate()
     
    },[])
    return(
        <footer>
        <div className="footerElement" />
        <div className="container">
            <div className="row">
            <div className="col-lg-3 col-sm-6">
                <div className="profile">
                <div className="imgDiv">
                    <img src={footerLogo} alt="footerLogo" />
                </div>
                <h2>Follow Us</h2>
                <div className="socialDiv">
                    <a className="mr-2" href="https://www.facebook.com/profile.php?id=100085562977140" target="_blank" rel="noopener noreferrer">
                    <img src={footerFb} alt="footerFb" />
                    </a>
                    <a href="https://instagram.com/lumen_spa?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                    <img src={footerInsta} alt="footerInsta" />
                    </a>
                </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="linkDiv">
                <h6>Quick Links</h6>
                <div className="footerLink">
                    <ul>
                    <li><Link to="/" >Home</Link></li>
                    <li><Link to="/about" >About Us</Link></li>
                    <li><Link to="/" onClick={handleClickScroll}>Services</Link></li>
                    <li><Link to="/policy" >Spa Policy</Link></li>
                    <li><Link to="/contact" >Contact Us</Link></li>
                    </ul>
                </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-3 col-sm-6">
                <div className="contactDiv">
                <h6>Contact</h6>
                <div className="footerLink">
                    <p className="py-1">
                        <a className="d-flex" href="https://goo.gl/maps/7EyzjqraAMYGomGs6" target="_blank">
                            <img className="mr-2" src={footerLocation} alt="" /> 2297 Main St. C Tewksbury, MA 01876 
                        </a>
                    </p>
                    <p className="py-1">
                        <a className="d-flex" href="tel:978-955-0086">
                            <img className="mr-2" src={FooterPhone} alt="" /> 978-955-0086
                        </a>
                    </p>
                    <p className="py-1">
                        <a className="d-flex" href="mailTo:contact@lumenspaandlaser.com">
                            <img className="mr-2" src={footerMail} alt="" /> <div className="wordBreak"> contact@lumenspaandlaser.com</div> 
                            </a>
                        </p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="contactDiv">
                <h6>Opening Hours</h6>
                <div className="footerLink">
                    <p>Monday - Friday</p>
                    <p>9:00 AM - 6:00 PM</p>
                    <p>Saturday</p>
                    <p>9:00 AM - 3:00 PM </p>
                    <p>Sunday Closed</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        <a className="whatsapp" href="tel:9789550086" ><img src={whatsapp} alt="" /></a>
        <div className="copyrightRow">Copyright © 2022 Lumen Spa and Laser. All rights reserved. • <a href="https://inoverus.com" target="_blank"> Design by Inoverus</a></div>
        </footer>
    )
}