import { useState } from "react";
import axios from "axios";
import { ref, set, push, onValue } from "firebase/database";
import {firebaseDb} from "./firebaseConfig";
import "../../assets/css/admin.css"
import { useNavigate } from "react-router-dom";

export const Login = ({setadminLogin}) =>{
    const [formVal,setFormVal] = useState({
        email:"",
        password:"",
    })
    var navigate = useNavigate()
    const [loader, setLoader] = useState(false);

    const handleChange =(e) =>{
        setFormVal({...formVal,[e.target.name]:e.target.value})
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        if(formVal.email != "" && formVal.password != ""){
            setLoader(true)
            const starCountRef = ref(firebaseDb, 'admin/' );
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                if (data.email == formVal.email && data.password == formVal.password) {
                    alert("login success");
                    setadminLogin(true)
                    setLoader(false)
                    navigate("/admin/dashboard")
                }else{
                    alert("Invalid Credentials");
                    setLoader(false)

                }
            });
           
        }else{
            alert("Please fill fields")
        }
    }

    // set(ref(firebaseDb, 'admin/' ), formVal)
    //         .then((res)=>{
    //             console.log(res);
    //         })
    //         .catch((err)=>{
    //             console.log(err);
    //         })
    
  
    return(
        <>  
            <div className="loginPage">
                <div className="loginCard ">
                    <h1>Login</h1>
                    <form >
                        <div className="txt_field">
                            <input placeholder="Email" className="form-control mb-3" onChange={(e)=> handleChange(e)} name="email" value={formVal.email} type="email" required />
                            {/* <label>Email</label> */}
                        </div>
                        <div className="txt_field">
                            <input placeholder="Password" className="form-control mb-4" onChange={(e)=> handleChange(e)} name="password" value={formVal.password} type="password" required />
                            {/* <label>Password</label> */}
                        </div>
                        <button className="btn btn-block submitBtn" onClick={(e)=>handleSubmit(e)}>
                            {loader ? 
                            <div className="loaderMain">
                                <div class="loader4"></div>
                            </div>   
                            : 
                            "Submit"
                            }    
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}