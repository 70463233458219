import { Navbar } from "./navbar"
import eyelash1 from "../assets/images/eyelash1.jpg"
import eyelash2 from "../assets/images/eyelash2.jpg"
import eyelash3 from "../assets/images/eyelash3.jpg"
import eyelash4 from "../assets/images/eyelash4.jpg"
import { useEffect } from "react"
import {animate} from "../assets/js/animate"

export function EyeLash() {
    useEffect(()=> {
        animate()
    },[])
    
    return(
        <div class="facialPage">
            <Navbar />
            <section className="facialBanner">
            <h1 className="themeHeadingLg text-center">Eyelash Extension</h1>
            </section>
            <section className="FacialsSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-6">
                    <h1 className="themeHeadingMd mb-4 animatable slideInUp">Eyelash Extensions</h1>
                    <p className="themeTextLg mb-3 animatable slideInUp">Lumen Spa &amp; Laser is a comfortable place for luxurious eyelash extensions and eyebrow services. We create an inviting experience that ensures luxurious extensions designed specifically for your beautiful features.</p>
                    <p className="themeTextLg mb-3 animatable slideInUp">Our professional stylists with work to extenuate your facial structure and inner beauty that ends with an extension perfect for your look, face, and lifestyle. We want you to feel empowered, strong, and confident with eyelash extensions meant to help you take on the world. Our eyelash extension services include:</p>
                    <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp" >BOOK NOW</a>
                </div>
                <div className="col-lg-6">
                    <div className="imgdiv animatable zoomIn">
                    <img className="img-fluid" src={eyelash1} alt="eyelash1" />
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="FacialsSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-6 ">
                    <h1 className="themeHeadingMd mb-4 animatable slideInUp">Classics full set | 120 Min. $100</h1>
                    <p className="themeTextLg mb-0 animatable slideInUp">They are simple, beautiful, natural-looking lash extensions.</p>
                    <p className="themeTextLg mb-0 animatable slideInUp">It's basically one extension to one natural lash.</p>
                    <div className="timeElectro animatable slideInUp">
                    <p className="themeTextLg mb-0">2 weeks refill | 70 Min. $50 and up</p>
                    <p className="themeTextLg mb-0">3 weeks refill | 80 Min. $70 and up</p>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="imgdiv animatable zoomIn" >
                    <img className="img-fluid" src={eyelash2} alt="" />
                    </div>
                </div>
                </div>
                {/* <p class="themeTextLg mt-5 animatable slideInUp">To learn more about our electrolysis services, please feel free to reach out to our expert team. We are more than happy to address any concerns you may have so your customized treatment plan goes smoother.</p> */}
            </div>
            </section>
            <section className="FacialsSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-5 ">
                    <div className="imgdiv animatable zoomIn" >
                    <img className="img-fluid" src={eyelash3} alt="" />
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="hybridFulSet">
                    <h1 className="themeHeadingMd mb-4 animatable slideInUp">Hybrid full set | 150 Min. $150</h1>
                    <p className="themeTextLg mb-0 animatable slideInUp">Hybrid is a combination of Volume and Classics lash extensions; it will enhance volume and length. </p>
                    {/* <p class="themeTextLg mb-0 animatable slideInUp"></p> */}
                    <div className="timeElectro animatable slideInUp">
                        <p className="themeTextLg mb-0">2 weeks refill | 80 Min. $70 and up</p>
                        <p className="themeTextLg mb-0"> 3 weeks refill | 100 Min $90 and up</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="FacialsSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-6 ">
                    <h1 className="themeHeadingMd mb-4 animatable slideInUp">Light Volume | 150 Min.  $200</h1>
                    <p className="themeTextLg mb-0 animatable slideInUp">Creating a fuller lash make up look.</p>
                    <div className="timeElectro animatable slideInUp">
                    <p className="themeTextLg mb-0">2 weeks refill | 80 Min. $100 and up</p>
                    <p className="themeTextLg mb-0">3 weeks refill | 100 Min. $130 and up</p>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="imgdiv animatable zoomIn" >
                    <img className="img-fluid" src={eyelash4} alt="" />
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
    )
}