import { Navbar } from "./navbar"
import cancelIco from "../assets/images/cancelIco.svg"
import arrival from "../assets/images/arrival.svg"
import noShows from "../assets/images/noShows.svg"
import children from "../assets/images/children.svg"
import cellPhone from "../assets/images/cellPhone.svg"
import pets from "../assets/images/pets.svg"
import food from "../assets/images/food.svg"
import returnPolicy from "../assets/images/returnPolicy.svg"



export function Policy() {
    return(
        <div class="facialPage">
            <Navbar />
            <section className="facialBanner">
          <h1 className="themeHeadingLg text-center">Spa Policy</h1>
        </section>
        <section className="policySec">
          <div className="container">
            <div className="contentMain">
              <div className="icon">
                <img src={cancelIco} alt="" />
              </div>
              <h2>Cancelation</h2>
              <p>A 24 hours notice will be required to cancel or reschedule any appointment. A cancellation fee of 50% of the service schedule will be charged for appointments canceled in less than 24 hours.                  </p>
              <p>In consideration and respect of our professional's time, we kindly ask for a 24-hour notice to cancel any appointment. The appointment reserved is especially for you; if you don’t give us enough time, we will not be able to schedule another client, and our professionals will lose their time and opportunity to provide a service for another client.</p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={arrival} alt="arrival" />
              </div>
              <h2>Arrivals</h2>
              <p>Clients must arrive 5-10 minutes before their appointment time. We kindly ask the client to arrive 5-10 minutes before their appointments to have enough time to complete any necessary paperwork.</p>
              <p>Remember, if you are late, the time of your service will be adjusted so the following appointments will not be delayed.</p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={noShows} alt="noShows" />
              </div>
              <h2>No Shows </h2>
              <p> A full payment of the schedule service will be charged for those who do not show up for their appointments. Future appointments will be required full payment at the time of booking. </p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={children} alt="children" />
              </div>
              <h2>Childern</h2>
              <p>Children are not allowed in the Spa for the safety of the children and the enjoyment of the other guests. Although we love babies and children, Lumen Spa &amp; Laser is an adult environmentand we kindly ask you to make child arrangements before your appointment. </p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={cellPhone} alt="cellPhone" />
              </div>
              <h2>Cell phones </h2>
              <p>Please silence your cell phone and refrain from talking on the phone to keep a relaxing environment.</p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={pets} alt="" />
              </div>
              <h2>Pets</h2>
              <p>No animals or pets shall be allowed in the spa due to the State Board Regulations. We love animals, but a Spa is not a good environment for them; also, some clients may be allergic.</p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={food} alt="" />
              </div>
              <h2>Food and Drink</h2>
              <p>No food should be eaten inside the spa to keep a nice and fresh environment. Drinks are allowed.</p>
            </div>
            <div className="contentMain">
              <div className="icon">
                <img src={returnPolicy} alt="" />
              </div>
              <h2>Product Return Policy</h2>
              <p>The product bought cannot be returned unless it is causing an allergic reaction. Every skin is different, and some need more time to get used to.</p>
            </div>
          </div>
        </section>
       
        </div>
    )
}