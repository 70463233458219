import { useEffect, useState } from "react";
import { AdminNavbar } from "./adminNavbar";
import axios from "axios";
import { onChildAdded, ref, onValue } from "firebase/database";
import {firebaseDb} from "./firebaseConfig";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export function AdminContact() {
    const [tableData,setTableData]= useState([])
    const [Loader,setLoader]= useState(false);
    const [copyText,setcopyText]= useState("copy to clipboard");
    const [copyTextAll,setcopyTextAll]= useState("copy all Email");
    const [allEmail,setAllEmail]= useState([])

    const getData = async () =>{
        setLoader(true)

        const commentsRef = ref(firebaseDb, 'contactRecord');
        onValue(commentsRef, (snapshot) => {
            let records= []
            let emailRec= []
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                records.push({"key":childKey ,"data":childData})
                emailRec.push(childData.email)
            });
            setAllEmail(emailRec)
            setTableData(records)
            setLoader(false)
        })
      
    }

    useEffect(()=> {
        getData()
    },[])

    function handleCopy(params) {
        console.log(params);
        setcopyText("copied")
        setTimeout(() => {
            setcopyText("copy to clipboard")
        }, 3000);
    }    
    function handleCopyAll(params) {
        console.log(params);
        setcopyTextAll("copied")
        setTimeout(() => {
            setcopyTextAll("copy all Email")
        }, 3000);
    }    

    return(
        <>
    
            <div className="adminPage">
                <div className="container">
                    <section className="contactSection">
                        <h1>Contact Form Data</h1>
                        {tableData.length >0 ?
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    
                                    <th scope="col">Name</th>
                                    <th scope="col">Email
                                        <CopyToClipboard text={allEmail?.toString()} onCopy={(e) => handleCopyAll(e)} >
                                            <span className="copyClipboard ">
                                                <i class="far fa-clone ml-2"></i>
                                                <div className="copyText right">{copyTextAll}</div>
                                            </span>
                                        </CopyToClipboard>
                                    </th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Message</th>
                                    {/* <th scope="col"></th> */}
                                </tr>
                                </thead>
                                <tbody>
                                    {Loader?
                                    <tr>
                                        <td colSpan={6}>

                                        <div className="loaderMain">
                                            <div class="adminloader"></div>

                                        </div>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                    { tableData.map((item,i)=>(
                                        <tr>
                                            <th>{item?.data?.name}</th>
                                            <td>
                                                <div>
                                                {item?.data?.email} 
                                                    <CopyToClipboard text={item?.data?.email} onCopy={(e) => handleCopy(e)} >
                                                        <span className="copyClipboard">
                                                            <i class="far fa-clone ml-2"></i>
                                                            <div className="copyText">{copyText}</div>
                                                        </span>
                                                    </CopyToClipboard>
                                                </div> 
                                            </td>
                                            <td>{item?.data?.phone}</td>
                                            <td>{item?.data?.subject}</td>
                                            <td>{item?.data?.message}</td>
                                            {/* <td>delete Icon</td> */}
                                        </tr>
                                    ))}
                                    </>
                                    }
                            
                                </tbody>
                            </table>
                        </div>
                        :
                        <h2>No Data</h2>
                        }

                       
                    </section>
                </div>
            </div>
        </>
    )
    
}