import { useEffect } from "react"
import { Navbar } from "./navbar"
import facial1 from "../assets/images/facial1.jpg"
import facial2 from "../assets/images/facial2.jpg"
import facial3 from "../assets/images/facial3.jpg"
import facial4 from "../assets/images/facial4.jpg"
import facial5 from "../assets/images/facial5.jpg"
import facial6 from "../assets/images/facial6.jpg"
import facial7 from "../assets/images/facial7.jpg"
import facial8 from "../assets/images/facial8.jpg"
import facial9 from "../assets/images/facial9.jpg"

import adOnElement from "../assets/images/adOnElement.png"
import acneLogo from "../assets/images/acneLogo.png"
import {animate} from "../assets/js/animate"

export function Facial() {
  useEffect(()=> {
    animate()
},[])
    return(
        <div class="facialPage">
            <Navbar />
            <section className="facialBanner">
          <h1 className="themeHeadingLg text-center">Facials</h1>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Facials</h1>
            <p className="themeTextLg animatable slideInUp">Rejuvenate your face with natural radiance through our custom and advanced facial treatments. We use the leading skincare procedures and products to ensure you get that wonderful glow that helps firm and tone your skin’s appearance.</p>
            <p className="themeTextLg mb-5 animatable slideInUp">Our highly trained estheticians will craft a unique facial experience designed to treat your specific needs and challenges. If you do not know where to start, don’t worry! We also offer a free consultation to help you along your facial and skincare journey.</p>
            <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Skincare Consultation | FREE</h1>
            <p className="themeTextLg mb-0 animatable slideInUp">Not sure what to choose or want to meet us before you decide? Choose this option for a free, no-obligation consultation to meet with one of our skincare experts!</p>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial1} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1 animatable slideInUp">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Luminance Facial | 60 Min. $90</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Enjoy this relaxing facial perfect for all skin types. The facial includes a double cleanse under steam, exfoliation, extraction, massage, and finishing mask. This treatment is also a fantastic way for you to prep your skin for other procedures!</p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Acne Light Facial | 60 Min. $100</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">A perfect new option to control mild to moderate acne. We use a blue laser light to fight acne at its core. A deep pore cleanse, extractions, and mask customized to your skin type. A series of treatments are needed to obtain the best long-term results.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv acneL animatable zoomIn">
                  <img className="img-fluid" src={facial2} alt="" />
                  <img className="logo" src={acneLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial3} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Lumen’s Microinfusion Facial | <span className="dib"> 45 Min. $195/ $225</span></h1>
                <p className="themeTextLg mb-0 animatable slideInUp">This invigorating microinfusion contains 24k gold needles, which are thinner than a human hair! This treatment also “stamps'' a custom of luxurious vitamins and nutrients into your skin with minimal side effects and downtime. It's an excellent option for people of all ages, especially before a big event or occasion where you’d like your skin to appear extra luminous. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Microneedling Stem Cells | $250</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Increasing collagen and elastin production internally and helping the skin absorb essential nutrients during the application process is the purpose of our microneedling treatments. Microneedling helps to resolve scarring and stretch marks and is effective on multiple body areas.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial5} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Glow Dermaplaning Facial | <span className="dib">  60 Min. $130 </span></h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Dermaplaning removes the outermost layers of dead cells using a sterile surgical blade, leaving the skin immediately smooth, supple, and your makeup will glide on like butter! Add to a facial for an extra glow, or do it on its own! </p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Diamond Microdermabrasion | 60 Min. $120</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">This treatment uses a microdermabrasion wand embellished with a diamond tip to move over and gently exfoliate the top layer of the skin. This helps to vacuum the dead cells and impurities that dull the skin - improving tone and texture while unveiling a brighter, clearer, and smoother complexion. It includes a finish with a soothing skin mask.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial6} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial7} alt="" />
                </div>  
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Ultrasonic Transformation Facial | 60 Min. $120 </h1>
                <p className="themeTextLg mb-0 animatable slideInUp">An anti-aging treatment that combines ultrasonic exfoliation with a Plant Stem Cell Serum to plump and firm wrinkles, even out skin tone, and provide a more vibrant complexion. This treatment includes an ultra-hydrating hyaluronic cream to reduce fine lines. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Relaxing Back Facial | <span className="dib"> 60 Min. $120</span></h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Designed specifically for your back, this purifying facial will help exfoliate, hydrate, and repair your skin. Lastly, a stress-relieving aromatherapy massage and a customized masque will leave you relaxed and your skin radiant. This is truly a “facial” for your back.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial8} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={facial9} alt="" />
                </div>  
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Chemical Peel | 45 Min. $150</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">A chemical peel uses a chemical solution to remove layers of skin, revealing the more youthful skin underneath. Chemical peels can reduce or improve fine lines and wrinkles, acne, scars, uneven skin coloring, and other skin imperfections.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="facialAddOnSec">
          <img className="adOnElement" src={adOnElement} alt="" />
          <div className="container">
            <h1 className="themeHeadingLg mb-5 text-center">Facials Add-On</h1>
            <div className="contentDiv">
              <h1 className="themeHeadingMd mb-3  animatable slideInUp">Galvanic | 15 Min. $40</h1>
              <p className="themeTextLg  animatable slideInUp">Galvanic treatment is a good option for hydrating dry skin to increase moisture retention, pushing the product deep into the epidermal layers. Also, emulsifies and softens the sebum and keratin found in hair follicles. The skin gets a deep cleaning and softening at the same time.</p>
            </div>
            <div className="contentDiv">
              <h1 className="themeHeadingMd mb-3  animatable slideInUp">Ultrasonic | 15 Min. $40</h1>
              <p className="themeTextLg  animatable slideInUp">An anti-aging treatment that combines ultrasonic exfoliation with a Plant Stem Cell Serum to pump and firm wrinkles, even out skin tone, and provide a more vibrant complexion. </p>
            </div>
            <div className="contentDiv">
              <h1 className="themeHeadingMd mb-3  animatable slideInUp">Microdermabrasion | 15 Min. $40</h1>
              <p className="themeTextLg  animatable slideInUp">This treatment uses a microdermabrasion wand embellished with a diamond tip to move over and gently exfoliate the top layer of the skin.</p>
            </div>
            <div className="contentDiv">
              <h1 className="themeHeadingMd mb-3  animatable slideInUp">LED light | 30 Min. $40</h1>
              <p className="themeTextLg  animatable slideInUp">LED light therapy naturally helps to relieve pain, treat acne, and reduce signs of aging through the power of light. Your skin tone and texture will improve, and inflammation will be significantly reduced with this LED treatment. </p>
            </div>
            <div className="contentDiv">
              <h1 className="themeHeadingMd mb-3  animatable slideInUp">Myolift Microcurrent | 30 Min. $ 50</h1>
              <p className="themeTextLg  animatable slideInUp">MyoLift™ is an FDA-cleared and clinically proven microcurrent machine using true microcurrent technology to exercise the facial muscles and re-educate them. The treatment delivers low-level, electrical microcurrent impulses to strategic locations on the face to improve the facial tone for a more youthful appearance. </p>
            </div>
          </div>
        </section>
        </div>
    )
}