import { useEffect } from "react"
import electrolysis1 from "../assets/images/electrolysis1.jpg"
import electrolysis2 from "../assets/images/electrolysis2.jpg"
import { Navbar } from "./navbar"
import {animate} from "../assets/js/animate"

export function Electrolysis() {
  useEffect(()=> {
    animate()
},[])
    return(
    <div class="facialPage">
        <Navbar />
        <section className="facialBanner">
          <h1 className="themeHeadingLg text-center">Electrolysis</h1>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Electrolysis</h1>
                <p className="themeTextLg mb-3 animatable slideInUp">At Lumen Spa and laser  we use four types of modalities in ELECTROLYSIS PERMANENT HAIR REMOVAL:</p>
                <p className="themeTextLg mb-3 animatable slideInUp"> <span> GALVANIC:</span> Technique that uses a direct current to transform skin’s water and salt into sodium hydroxide, which thus destroys the follicle and hair germ cells.</p>
                <p className="themeTextLg mb-3 animatable slideInUp"><span> THERMOLYSIS:</span> This system uses an alternates current to heat the water in skin to destroy the follicle and hair germ cells.</p>
                <p className="themeTextLg mb-3 animatable slideInUp"><span> BLEND:</span> A specialized approach to dismantle the hair follicle and its cells, a combination of galvanic and thermolysis is used.</p>
                <p className="themeTextLg mb-3 animatable slideInUp"><span> SYNCHRO:</span>  To get faster results, specifically for the coarser and stubborn hairs, we use special software called synchro.</p>
                <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={electrolysis1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={electrolysis2} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <p className="themeTextLg mb-4 animatable slideInUp">Treatment will depend on your unique situation, which is why we have such an experienced staff. We want to ensure that every client who experiences our electrolysis treatment has a highly rewarding outcome. This includes sessions of:</p>
                <div className="timeElectro animatable slideInUp">
                  <p className="themeTextLg mb-0">15 minutes $40</p>
                  <p className="themeTextLg mb-0">30 minutes $60</p>
                  <p className="themeTextLg mb-0">45 minutes $80</p>
                  <p className="themeTextLg mb-0">1 hour $100</p>
                </div>
              </div>
            </div>
            <p className="themeTextLg mt-5 animatable slideInUp">To learn more about our electrolysis services, please feel free to reach out to our expert team. We are more than happy to address any concerns you may have so your customized treatment plan goes smoother.</p>
          </div>
        </section>
    </div>
    )
}