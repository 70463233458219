import { Navbar } from "./navbar";
import treatement1 from "../assets/images/treatement1.jpg"
import treatement2 from "../assets/images/treatement2.jpg"
import treatement3 from "../assets/images/treatement3.jpg"
import treatement4 from "../assets/images/treatement4.jpg"
import treatement5 from "../assets/images/treatement5.jpg"
import {animate} from "../assets/js/animate"
import { useEffect } from "react";

export function Treatment() {
  useEffect(()=> {
    animate()
  },[])
    return(
        <div className="facialPage">
            <Navbar />
            <section className="facialBanner">
          <h1 className="themeHeadingLg text-center">Body Treatment</h1>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Body Treatments</h1>
            <p className="themeTextLg animatable slideInUp">Every one of us is different, with unique challenges to our movement, where we hold stress, and lifestyle needs. That is why we provide a range of body treatments designed to relax your sore muscles as well as treat those areas where you want a bit more attention.</p>
            <p className="themeTextLg mb-5 animatable slideInUp">Our expert therapists and technicians know how to combine various techniques that address your specific needs. Therefore, we are highly motivated to help you feel your best every time your visit Lumen Spa &amp; Laser with body treatments like:</p>
            <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Post-Operative Consultation needed</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Post Operative Lymphatic drainage is for those who have undergone general surgery, orthopedic, or plastic surgery. Helps to reduce swelling and bruising and also helps to prevent the formation of fibrosis. Lymphatic drainage decreases recovery time and improves results. Promotes circulation, reduces inflammation and helps scar tissue heal. A number of sessions will be needed to achieve the best results.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={treatement1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={treatement2} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Lymphatic Drainage | 60 Min. $80</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Lymphatic drainage massage is a gentle form of massage used to relieve swelling and water retention. It also improves the immune system due to the critical elimination of toxins.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Relaxation Massage | 60 Min. $80</h1>
                <p className="themeTextLg mb-0 animatable slideInUp">Relaxation massage helps to relieve stress and muscle tension. It also improves your circulation and blood flow, so your organs and skin get more oxygen, aiding with lymphatic drainage.</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={treatement3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={treatement4} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Body Slimming Treatment (non-surgical) | 75 Min. $90 </h1>
                <p className="themeTextLg mb-0 animatable slideInUp">This treatment is to help to modulate the body with a combination of electrotherapy such as Radio Frequency, ultrasound cavitation, microcurrents, as well as body contouring massages, followed by a mint and magnesium wrap in the blanked infrared sauna. This massage is recommended for those who want to reduce the appearance of cellulite, tighten the skin, and help you lose measurements or maintain shape.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Body Wrap | 30 Min. $35</h1>
                <p className="themeTextLg mb-4 animatable slideInUp">This treatment focuses on detoxification and weight loss by raising the body temperature. A few benefits of the body wrap are detoxification, weight loss, and muscle relaxation. Add this to any of our body treatments desired.</p>
                <p className="themeTextLg mb-0 animatable slideInUp"> Packages available for Post-lipo and Body contouring</p>
              </div>
              <div className="col-lg-6">
                <div className="imgdiv animatable zoomIn">
                  <img className="img-fluid" src={treatement5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}