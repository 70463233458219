import { useState } from "react";
import { Navbar } from "./navbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {  ref, set, push } from "firebase/database";
import {firebaseDb} from "./admin/firebaseConfig";

export function Contact(params) {

    const [formVal,setFormVal] = useState({
        name:"",
        email:"",
        phone:"",
        subject:"",
        message:""
    })
      // notification setting
      const notify = (response,types) => 
      types(response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleChange =(e) =>{
        setFormVal({...formVal,[e.target.name]:e.target.value})
    }
    const handleSubmit = async (e) =>{
        e.preventDefault()
        var valid = /^!*(\d!*){10,}$/;
        var validM = valid.test(formVal.phone)
        
        if(formVal.name.length < 3) return notify("Username must be greater than 3 character",toast.error)
        if(formVal.name.length > 30) return notify("Username should be less than 30 character",toast.error);
        if(formVal.phone.includes(' ') > 30) return notify("Spaces are not allowed in phone number",toast.error);
        if(!validM || formVal.phone.length < 10) return notify("Phone must contain atleast 10 digits",toast.error);
        
        
        console.log(formVal);
        const db = firebaseDb
        const postListRef = ref(db, 'contactRecord');
        const newPostRef = push(postListRef);
        set(newPostRef, formVal)
        .then((res)=>{
            console.log(res);
            notify("Thanks for contacting us, Lumen Spa team will connect you.",toast.success)
            setFormVal({
                name:"",
                email:"",
                phone:"",
                subject:"",
                message:""
            })
        })
        .catch((err)=>{
            console.log(err);
        })
                
      
        // await axios.post("https://lumen-spa-and-laser-default-rtdb.firebaseio.com/contactRecord.json",formVal)
        // .then((res)=>{
        //     console.log(res);
        // })
        // .catch((err)=>{
        //     console.log(err);
        // })
    }
    
    return(
        <div class="ContactPage">
            <Navbar />
            <section className="contactUsSec">
                <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                    <h1 className="themeHeadingMd mb-4">Contact Us</h1>
                    <p className="themeTextLg mb-4">We want to hear from you! Please feel free to use our contact form below with any questions about the exceptional services at Lumen Spa &amp; Laser.</p>
                    <p className="themeTextLg">Our professional support team will respond quickly with answers to your inquiries or help schedule a future appointment with our spa in Tewksbury. Thank you, and we look forward to providing you a luxurious spa experience.</p>
                    </div>
                    <div className="col-lg-7">
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <div className="inputRow">
                        <div className="col1 mr-3">
                            <label className="themeTextLg mb-1">name</label>
                            <input onChange={(e)=>handleChange(e)} name="name" value={formVal.name} required className="form-control" type="text" placeholder="username" />
                        </div>
                        <div className="col1">
                            <label className="themeTextLg mb-1">Email ID</label>
                            <input onChange={(e)=>handleChange(e)} name="email" value={formVal.email} required className="form-control" type="email" placeholder="username@example.com" />
                        </div>
                        </div>
                        <div className="inputRow">
                        <div className="col1 mr-3">
                            <label className="themeTextLg mb-1">Phone</label>
                            <input onChange={(e)=>handleChange(e)} name="phone" value={formVal.phone} required className="form-control" type="tel" placeholder="124567889" />
                        </div>
                        <div className="col1">
                            <label className="themeTextLg mb-1">Subject</label>
                            <input onChange={(e)=>handleChange(e)} name="subject" value={formVal.subject} required className="form-control" type="text" placeholder="marketing consulting" />
                        </div>
                        </div>
                        <div>
                        <label className="themeTextLg mb-1">Your message</label>
                        <textarea onChange={(e)=>handleChange(e)} name="message" value={formVal.message} required className="form-control" type="text" placeholder="Write message here" id cols={20} rows={6} defaultValue={""} />
                        </div>
                        <button className="themeBtnDark mt-4" type="submit">Submit Message</button>
                    </form>
                    </div>
                </div>
                </div>
            </section>
          <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>      

        </div>
    )
}