import { Navbar } from "./navbar"
import waxingCover from "../assets/images/waxingCover.jpg"
import {animate} from "../assets/js/animate"
import { useEffect } from "react"


export function Waxing() {
  useEffect(()=> {
    animate()
  },[])
    return(
        <div class="facialPage">
            <Navbar />
        <section className="facialBanner">
          {/* <img className="headerLines" src="" alt="" /> */}
          <h1 className="themeHeadingLg text-center">Waxing</h1>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Waxing</h1>
            <p className="themeTextLg animatable slideInUp">Whether you want a quick treatment to add an extra glow for your hot girl summer or need some quality pampering - we’ve got you covered. Lumen Spa &amp; Laser offers a clean, comfortable space with technicians highly trained in waxing any target area.</p>
            <p className="themeTextLg mb-5 animatable slideInUp">Our professional team offers a wide range of waxing services with products ideally formulated for the best possible results. We have perfected the art of waxing, so you leave with a smoother, longer-lasting result. Our waxing services include:</p>
            <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>            <div className="waxingCover mt-3">
              <img className="img-fluid" src={waxingCover} alt="" />
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Face Waxing</h1>
            {/* <p class="themeTextLg mb-4 animatable slideInUp">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p> */}
            <div className="waxCategoryMain">
              <div className="col1">
                <div className="content animatable slideInUp">
                  <div className="name">Eyebrow</div>
                  <div className="price">$20</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Eyebrow wax and tinting</div>
                  <div className="price">$35</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Upper lip</div>
                  <div className="price">$8</div>
                </div>
              </div>
              <div className="col2">
                <div className="content animatable slideInUp">
                  <div className="name">Chin</div>
                  <div className="price">$15</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Sideburns</div>
                  <div className="price">$15</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full face  (sideburns, upper lip, chin)</div>
                  <div className="price">$40</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Body waxing</h1>
            {/* <p class="themeTextLg mb-4 animatable slideInUp">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p> */}
            <div className="waxCategoryMain">
              <div className="col1">
                <div className="content animatable slideInUp">
                  <div className="name">Underarm</div>
                  <div className="price">$20</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Half arm </div>
                  <div className="price">$30</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full arm</div>
                  <div className="price">$55</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Upper back </div>
                  <div className="price">$25</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Middle back</div>
                  <div className="price">$25</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Lower back</div>
                  <div className="price">$25</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full back</div>
                  <div className="price">$70</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Chest</div>
                  <div className="price">$40</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Belly</div>
                  <div className="price">$40</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Chest + belly</div>
                  <div className="price">$70</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Female Brazilian </div>
                  <div className="price">$60</div>
                </div>
              </div>
              <div className="col2">
                <div className="content animatable slideInUp">
                  <div className="name">Female Brazilian and wings </div>
                  <div className="price">$75</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Bikini</div>
                  <div className="price">$40</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Inner tights</div>
                  <div className="price">$20</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full butt </div>
                  <div className="price">$50</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Upper leg </div>
                  <div className="price">$50</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Lower leg</div>
                  <div className="price">$40</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full leg</div>
                  <div className="price">$85</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Hands</div>
                  <div className="price">$20</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Feet</div>
                  <div className="price">$20</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">Full body</div>
                  <div className="price">$160</div>
                </div>
                <div className="content animatable slideInUp">
                  <div className="name">(underarm, Brazilian, full legs) </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}