
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/animate.css"
import {useLocation} from "react-router-dom";
import banner from "../assets/images/banner.png"
import leaf from "../assets/images/leaf.png"
import headerBg from "../assets/images/headerBg.png"
import whyChoose from "../assets/images/whyChoose.jpg"
import mission from "../assets/images/mission.jpg"
import serviceElement from "../assets/images/serviceElement.png"
import waxingIco from "../assets/images/waxingIco.svg"

import {animate} from "../assets/js/animate"

import client from "../assets/images/client.jpg"


import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar } from "./navbar";
import { onChildAdded, ref, onValue, set, push, remove, update } from "firebase/database";
import { firebaseDb } from "./admin/firebaseConfig";

export const Home = () =>{
    const [serviceData,setServiceData]= useState([])
    const [flag,setFlag]= useState(true)
    var location = useLocation()




    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        pauseOnHover: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };

    const getData = async () =>{
        // setLoader(true)
        const commentsRef = ref(firebaseDb, 'services');
        onValue(commentsRef, (snapshot) => {
            let records= []
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                records.push({"key":childKey ,"data":childData})
            });
            // console.log(records);
            setServiceData(records)
        //    setLoader(false)
        })
    }

    useEffect(()=> {
        getData()
    },[])
    useEffect(()=> {
        animate()
         
    },[])

    return(
    <>
       <Navbar />
        <div className="homePage">
            <section className="bannerSec">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <div className="content animatable fadeIn">
                    <h1>A Fresh Escape</h1>
                    <p className="desc">Leave the world behind for a while and treat yourself to the exceptional treatments available from our relaxing spa in  Tewksbury. At Lumen Spa &amp; Laser, we have bespoke solutions to leave you feeling fresh, at ease, and rejuvenated.</p>
                    <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark">BOOK NOW</a>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="banner">
                    <img className="img-fluid" src={banner} alt="" />
                    <img className="leaf" src={leaf} alt="" />
                    <img className="headerBg" src={headerBg} alt="" />

                    </div>
                </div>
                </div>
            </div>
            <div className="bannerFooter" />
            </section>
            <section className="whyChooseSec">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <h1 className="themeHeadingMd animatable slideInUp" >Why Choose Us?</h1>
                    <p className="themeTextLg animatable slideInUp" >Our professional technicians at Lumen Spa &amp; Laser have spent years combining modern technology with tried and true treatments for your skin, hair, and body. We offer everything from relaxing facials to full body massages and so much more.                </p>
                    <p className="themeTextLg animatable slideInUp" >We take the time to listen, consult, and treat your needs with single-use and routine preventative spa treatments. Each one is proven to offer advanced care that will smooth, lift, and add volume in the places you want the most.</p>
                    <p className="themeTextLg animatable slideInUp" >Proper skincare is essential to looking and feeling your best. Give us a call today, and let’s boost your self-confidence and natural glow together through our quality spa services.</p>
                    <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>
                </div>
                <div className="col-lg-6">
                    <div className="imgdiv animatable zoomIn" >
                    <img className="img-fluid" src={whyChoose} alt="" />
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="missionSec">
            <div className="container">
                <div className="row">
                <div className="col-lg-6 order-2 order-lg-1">
                    <div className="imgdiv  animatable zoomIn">
                    <img className="img-fluid" src={mission} alt="" />
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                    <h1 className="themeHeadingMd animatable slideInUp">Mission</h1>
                    <p className="themeTextLg animatable slideInUp">We keep things simple at Lumen Spa &amp; Laser. Our mission is to offer the latest treatments for the mind, body, face, and overall well-being of each and every client that walks through our doors.</p>
                    <p className="themeTextLg animatable slideInUp">For us, it is all about transforming your look into a healthy, happy outcome. That is why we have so many return and referral clients because we take the time to address the whole you.</p>
                </div>
                </div>
            </div>
            </section>
            <section className="ourServiceSec" id="service">
            <div className="topElement" />
            <div className="container pr">
                <img className="serviceElement" src={serviceElement} alt="image" />
                <div className="title text-center">What We Do</div>
                <h1 className="themeHeadingLg text-center mb-5">Our Services</h1>
                <div className="row" style={{position:"relative",zIndex:"5"}}>
                {serviceData?.map((item,id)=>(
                    <ServiceCard 
                    icon={item?.data.icon} 
                    heading={item?.data.heading} 
                    description={item?.data.description} 
                    pagePath={item?.data.pagePath} 
                    id={id}
                    />
                ))}
            
                </div>
            </div>
            <div className="bottomElement" />
            </section>
            <section className="whatClientSays">
            <div className="container">
                <h1 className="themeHeadingLg mb-5">What Our Clients Say</h1>
                <div className="row1">
                    <Slider {...settings}>
                    <div className="col h-100 mb-4">
                        <div className="clentCard animatable slideInUp ">
                        <div className="profileDiv">
                            {/* <div className="imgDiv"><img className="img-fluid" src={client} alt="client" /></div> */}
                            <div className="nameMain">
                            <p className="name">Kasia Catana</p>
                            <p className="time">13 hours ago</p>
                            </div>
                        </div>
                        <p className="themeTextMd mb-0">Absolutely the best of the best! Super knowledgeable on all things skin care and beyond. Cannot recommend enough!</p>
                        </div>
                    </div>
                    <div className="col h-100 mb-4">
                        <div className="clentCard animatable slideInUp animationDelay100" >
                        <div className="profileDiv">
                            {/* <div className="imgDiv"><img className="img-fluid" src={client} alt="client" /></div> */}
                            <div className="nameMain">
                            <p className="name">Yacely Velez</p>
                            <p className="time">2 months ago</p>
                            </div>
                        </div>
                        <p className="themeTextMd mb-0">Best place ever love getting my body wax by Herika she’s the best she’s lovely barely feel any pain she has the best hands for waxing..</p>
                        </div>
                    </div>
                    <div className="col h-100 mb-4">
                        <div className="clentCard animatable slideInUp animationDelay200" >
                        <div className="profileDiv">
                            {/* <div className="imgDiv"><img className="img-fluid" src={client} alt="client" /></div> */}
                            <div className="nameMain">
                            <p className="name">Danubia Santos</p>
                            <p className="time">a month ago</p>
                            </div>
                        </div>
                        <p className="themeTextMd mb-0">Herica is great and very professional. I have had several electrolysis treatments and definitely recommend it...</p>
                        </div>
                    </div>
                    <div className="col h-100 mb-4">
                        <div className="clentCard animatable slideInUp animationDelay300" >
                        <div className="profileDiv">
                            {/* <div className="imgDiv"><img className="img-fluid" src={client} alt="client" /></div> */}
                            <div className="nameMain">
                            <p className="name">Karen C</p>
                            <p className="time">6 months ago</p>
                            </div>
                        </div>
                        <p className="themeTextMd mb-0">Love the massages and facials, great that you can book online too</p>
                        </div>
                    </div>
                </Slider>
                </div>
            </div>
            </section>
            <section className="sceduleSec">
            <div className="container">
                <div className="innerCard">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Schedule Your Peace</h1>
                <p className="themeTextLg animatable slideInUp">Lumen Spa &amp; Laser is a premier, client-oriented day spa offering customized treatment experiences. We are an inclusive business working hard to ensure you have a rewarding and relaxing visit each and every time.</p>
                <p className="themeTextLg mb-3 animatable slideInUp">Please reach out today to learn more about our various services or schedule an appointment with our fantastic spa in Tewksbury team.</p>
                <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnOutline animatable slideInUp">Book now</a>
                </div>
            </div>
            </section>
        </div>
    </>
    )
}

const ServiceCard =({icon,heading,description,pagePath,id}) => {
    return(
        <div className={`col-lg-4 col-md-6 mb-4 animatable flipInY animationDelay${id}00`} key={id}>
            <div className="serviceCard">
                <div className="icoDiv"><img src={icon} alt="" /></div>
                <div className="heading">{heading}</div>
                <p className="themeTextMd">{description}</p>
                <div className="readMore btn"><Link to={pagePath}> Read More <i className="far fa-chevron-right ml-2" /></Link></div>
            </div>
        </div>
    )
}
