
import logo from "../../assets/images/LumenLogo.svg"

import { Link, useNavigate } from "react-router-dom"
export function AdminNavbar({setadminLogin}) {

    var navigate = useNavigate()
    
    const handleLogout = () =>{
        setadminLogin(false)
        navigate("/admin")
    }
    return(
        <header>
      
        <nav className="navbar navbar-expand-lg ">
        <div className="container">
            <Link className="navbar-brand" to="/">
            <img className="img-fluid" src={logo} alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="far fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/admin/dashboard">Contact</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/admin/service">Services</Link>
                </li>
             
            </ul>
                <button className="themeBtnDark" onClick={()=>handleLogout()}>logout</button>
          
            </div>
        </div>
        </nav>
    </header>
    )
}