import { Navbar } from "./navbar";
import wecomeImg from "../assets/images/wecomeImg.jpg"
import welcomeElement from "../assets/images/welcomeElement.png"
import headerBg from "../assets/images/headerBg.png"
import bespokFlower from "../assets/images/bespokFlower.png"
import pTeam from "../assets/images/professionalTeam.jpg"

import thumbnail from "../assets/video/thumbnail.jpg"
import video1 from "../assets/video/video1.mp4"
import {animate} from "../assets/js/animate"
import { useEffect } from "react";

export function About(params) {
    useEffect(()=> {
        animate()
    },[])
    return(
        <div class="aboutPage">
            <Navbar />
            <section className="welcomeSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-6">
                    <h1 className="themeHeadingLg mb-4 animatable slideInUp">Welcome to Lumen Spa &amp; Laser</h1>
                    <p className="themeTextLg animatable slideInUp">We are a leading provider of relaxing spa services, from the latest laser solutions to body slimming treatments. We strive to provide a safe, reliable, and luxurious experience for each of our clients through the traditions of our practice blended with modern solutions for hair removal, body rejuvenation, and exceptional skin care.</p>
                    <p className="themeTextLg mb-5 animatable slideInUp">Our team is client-focused, adjusting each service to our valued clients' unique needs and challenges. So whether you need a massage to shrug off the concerns of daily life or ultrasonic solutions for a target trouble area, we have a treatment just for you!</p>
                    <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>
                </div>
                <div className="col-lg-6">
                    <div className="imgdiv">
                        <img className="img-fluid animatable zoomIn" src={wecomeImg} alt="" />
                        <img className="img-fluid element" src={welcomeElement} alt="" />
                        <img className="headerBg" src={headerBg} alt="" />
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="bespokeSec">
            <div className="container pr">
                <img className="bespokFlower" src={bespokFlower} alt="" />

                <h1 className="themeHeadingLg mb-4 animatable slideInUp">Bespoke Spa Treatments</h1>
                <p className="themeTextLg mb-4 animatable slideInUp">At Lumen Spa &amp; Laser, we stand out among the competition because of our creative and innovative dedication to the latest treatments. Our expert team has spent years uncovering those unique services known to provide the highest quality results.</p>
                <p className="themeTextLg mb-4 animatable slideInUp">We are so well known in our industry for this diligence to stay up to date on the latest trends without sacrificing client care. When you walk into our spa in Tewksbury, you will be given every opportunity for a rewarding experience with the highest quality products and world-class brands to support your treatment.</p>
                <div className="videoMain">
                <video poster={thumbnail} width="100%" autoPlay="true" muted="true" loop playsInline="true" id="vid">
                    <source src={video1} type="video/mp4" />
                    {/* <source type="video/youtube" src="https://www.youtube.com/watch?v=AhP5Tg_BLIk" /> 
                            <source src="mov_bbb.ogg" type="video/ogg" />  */}
                </video> 
                </div>
            </div>
            </section>
            <section className="ProgesionalSec">
            <div className="container">
                <div className="row ai-center">
                <div className="col-lg-6">
                    <h1 className="themeHeadingLg mb-4 animatable slideInUp">Professional Team</h1>
                    <p className="themeTextLg animatable slideInUp">From the beginning, Lumen Spa &amp; Laser has assembled a team of experts with experience and personalized client care that goes beyond chain services. We want you to have an enjoyable and memorable experience with each of our team members, which is why we carefully select only those experts who excel in their areas of focus.</p>
                    <p className="themeTextLg mb-0 animatable slideInUp">Whether getting to those pesky deep tissue areas that need extra attention in a massage or perfectly crafting your eyelash extensions, our hybrid spa has the training you need for an outstanding result.</p>
                </div>
                <div className="col-lg-6">
                    <div className="imgdiv animatable zoomIn">
                    <img className="img-fluid" src={pTeam} alt="" />
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="lightPinkSec">
            <div className="container">
                <div className="innerCard">
                <h1 className="themeHeadingMd mb-4 animatable slideInUp">Why Wait?</h1>
                <p className="themeTextLg mb-3 animatable slideInUp">No matter if you are treating your friend to a relaxing massage or scheduling a group event for facials, we have bespoke spa services that deliver exceptional outcomes. Schedule an appointment or reach out to learn more about how we can ensure all your services, from chemical peels to post-operative care, are exactly what you need.</p>
                <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnOutline animatable slideInUp">Book now</a>
                </div>
            </div>
            </section>
        </div>
    )
}