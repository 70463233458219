import logo from './logo.svg';
import './App.css';
import { Home } from './component/home';
import {
  BrowserRouter as Router, Routes, Route, Link, useLocation
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css"
import { Footer } from './component/footer';
import { About } from './component/about';
import { Contact } from './component/contact';
import { Electrolysis } from './component/electrolysis';
import { EyeLash } from './component/eyelash';
import { Facial } from './component/facial';
import { LaserHair } from './component/laserHair';
import { Policy } from './component/policy';
import { Waxing } from './component/waxing';
import { Treatment } from './component/treatment';
import { Login } from './component/admin/login';
import { AdminContact } from './component/admin/adminContact';
import { useState } from 'react';
import { AdminNavbar } from './component/admin/adminNavbar';
import { AdminService } from './component/admin/adminService';


function App() {
  const [adminLogin, setadminLogin]= useState(false)
    var location = useLocation()
    console.log(location.pathname);
    if(location.pathname.includes("admin")){
      console.log("admin");
      console.log(adminLogin);
    }
  return (
    <>
        {location.pathname.includes("admin") ?
        <>
        {adminLogin && <AdminNavbar setadminLogin={setadminLogin} />}
        <Routes>
          <Route path='*' element={<h4>404</h4>} />
          {adminLogin?
          <>
          <Route path="/admin/dashboard" element={<AdminContact />} />
          <Route path="/admin/service" element={<AdminService />} />
          </>
          :
          <Route path="/admin" element={<Login setadminLogin={setadminLogin} />} />
        }
        </Routes>
        </>
        :
        <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/electrolysis" element={<Electrolysis />} />
          <Route path="/eyelash" element={<EyeLash />} />
          <Route path="/facials" element={<Facial />} />
          <Route path="/laserhair" element={<LaserHair />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/treatment" element={<Treatment />} />
          <Route path="/waxing" element={<Waxing />} />
        </Routes>
        <Footer />
        </>
        }


    </>
  );
}

export default App;
