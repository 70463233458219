import { initializeApp,  } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAu0awgITdMlOIMiQIrip1xjchGbugJqrQ",
    authDomain: "lumen-spa-and-laser.firebaseapp.com",
    databaseURL: "https://lumen-spa-and-laser-default-rtdb.firebaseio.com",
    projectId: "lumen-spa-and-laser",
    storageBucket: "lumen-spa-and-laser.appspot.com",
    messagingSenderId: "859676702786",
    appId: "1:859676702786:web:acee8e4f92c74a8cde15db",
    measurementId: "G-PQJFQ9Z8YF"
  };

  // Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseDb = getDatabase(app);
export const storage = getStorage(app);
// export default firebaseDb