import { useEffect, useState, useId } from "react";
import { AdminNavbar } from "./adminNavbar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { onChildAdded, ref, onValue, set, push, remove, update } from "firebase/database";
import {firebaseDb, storage} from "./firebaseConfig";
import { ref as refST, uploadBytes, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';

export function AdminService() {
    const [tableData,setTableData]= useState([])
    const [Loader,setLoader]= useState(false);
    const [pageLoader,setPageLoader]= useState(false);
    const [image,setImage]= useState(null);
    const [imageUpdate,setImageUpdate]= useState(null);
    const [selectKey,setSelectKey]= useState("");
    const [formVal,setFormVal] = useState({
        icon:"",
        heading:"",
        pagePath:"",
        description:"",
    })
    const [formValUpdate,setFormValUpdate] = useState({
        icon:"",
        heading:"",
        pagePath:"",
        description:"",
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShowUpdate = () => setShowUpdate(true);

   // notification setting
   const notify = (response,types) => 
   types(response, {
     position: "bottom-right",
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
 });
    var imgId = useId()

    const getData = async () =>{

        setLoader(true)
        const commentsRef = ref(firebaseDb, 'services');
        onValue(commentsRef, (snapshot) => {
            let records= []
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                records.push({"key":childKey ,"data":childData})
            });
            console.log(records);
            setTableData(records)
           setLoader(false)
        })
      
    }

    useEffect(()=> {
        getData()
    },[])

    const handleChange =(e) =>{
        if(e.target.name == "icon") {
            setImage(e.target.files[0])
        }
        setFormVal({...formVal,[e.target.name]:e.target.value})
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()

        if(image == null) return;
        setPageLoader(true)
        const imageRef = refST(storage, `images/${image.name}`);
        var imgUrl=await uploadBytes(imageRef,image)
            .then(async (res)=>{
                console.log(res);
                let url = await getDownloadURL(res.ref)
                if(url){
                    return url
                }
                console.log("image Uploaded ");
            }).catch((err)=>{
                console.log(err);
            })
        if(imgUrl) {
            let data ={...formVal,"icon":imgUrl}
            console.log(data);

            const db = firebaseDb
            const postListRef = ref(db, 'services');
            const newPostRef = push(postListRef);
            set(newPostRef, data)
            .then((res)=>{
                console.log(res);
                setPageLoader(false)
                handleClose()
                notify("Service added.",toast.success)
                setFormVal({
                    icon:"",
                    heading:"",
                    pagePath:"",
                    description:"",
                })
                setImage(null)
            })
            .catch((err)=>{
                console.log(err);
                notify("Something went wrong.",toast.error)
                setPageLoader(false)
            })
            }
    }

    const deleteData = (id) =>{
        console.log(id);
        setPageLoader(true)
        const deleteRef = ref(firebaseDb, 'services/' + id);
        remove(deleteRef)
        .then((res)=>{
            setPageLoader(false)
            notify("Data deleted.",toast.success)
        })
        .catch((err)=>{
            console.log(err);
            setPageLoader(false)
            notify("Something went wrong.",toast.error)
        })
    }
    
    const handleChangeUpdate =(e) =>{
        if(e.target.name == "icon") {
            setImageUpdate(e.target.files[0])
        }
        setFormValUpdate({...formValUpdate,[e.target.name]:e.target.value})
    }

    const handleUpdate = (data,id) =>{
        handleShowUpdate()
        setSelectKey(id)
        console.log(data);
        setFormValUpdate({
            heading: data.heading,
            pagePath: data.pagePath,
            description: data.description,
        })
    }
    const submitUpdate = async (e) =>{
        e.preventDefault()
        console.log(formValUpdate);
        console.log(selectKey);
        
        console.log(imageUpdate);
        setPageLoader(true)
        if(imageUpdate){
            const imageRef = refST(storage, `images/${imageUpdate.name}`);
            var imgUrl=await uploadBytes(imageRef,imageUpdate)
                .then(async (res)=>{
                    console.log(res);
                    let url = await getDownloadURL(res.ref)
                    if(url){
                        return url
                    }
                    console.log("image Uploaded");
                }).catch((err)=>{
                    console.log(err);
                })
                console.log(imgUrl);
                if(imgUrl) {
                    var data={...formValUpdate,"icon":imgUrl}
                    const postListRef = ref(firebaseDb, 'services/' + selectKey);
                    update(postListRef, data)
                    .then((res)=>{
                        console.log(res);
                        setPageLoader(false)
                        handleCloseUpdate()
                        setImageUpdate(null)
                        notify("Service updated.",toast.success)
                       
                    })
                    .catch((err)=>{
                        console.log(err);
                        notify("Something went wrong.",toast.error)
                        setPageLoader(false)
                    })
                }
        }else {
            const postListRef = ref(firebaseDb, 'services/' + selectKey);
            update(postListRef, formValUpdate)
            .then((res)=>{
                console.log(res);
                setPageLoader(false)
                handleCloseUpdate()
                notify("Service updated.",toast.success)
               
            })
            .catch((err)=>{
                console.log(err);
                notify("Something went wrong.",toast.error)
                setPageLoader(false)
            })
        }
    }
    return(
        <>
            <div className="adminPage adminService">
                <div className="container">
                    <section className="contactSection">
                        <div className="row1">
                            <h1 className="mt-3">Home Service Sections</h1>
                            <button className="btn btn-dark mb-3" onClick={handleShow}>Add Section</button>
                        </div>
                        {tableData.length >0 ?
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    
                                    <th scope="col">icon</th>
                                    <th scope="col">heading</th>
                                    <th scope="col">pagePath</th>
                                    <th scope="col">description</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {Loader?
                                    <tr>
                                        <td colSpan={6}>

                                        <div className="loaderMain">
                                            <div class="adminloader"></div>
                                        </div>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                    { tableData.map((item,i)=>(
                                        <tr key={item.key}>
                                            <th>
                                                
                                                <div className="logoDiv">
                                                    <img className="imgFluid" src={item?.data?.icon} alt="icon" />
                                                </div>
                                            </th>
                                            <td>{item?.data?.heading}</td>
                                            <td>{item?.data?.pagePath}</td>
                                            <td>{item?.data?.description}</td>
                                            <td>
                                                <div className="editIcons d-flex">
                                                    <i class="fas fa-edit mr-2" onClick={()=>handleUpdate(item?.data,item.key)}></i>
                                                    <i class="fas fa-trash-alt" onClick={()=>deleteData(item.key)}></i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </>
                                    }
                            
                                </tbody>
                            </table>
                        </div>
                        :
                        <h2>No Data</h2>
                        }

                       
                    </section>
                </div>
            </div>
            {/* add service modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="addSecForm" onSubmit={(e)=> handleSubmit(e)}>

                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Service Icon</label>
                            <input name="icon" value={formVal.icon} required onChange={(e)=> handleChange(e)} className="form-control" type="file"  />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Heading</label>
                            <input name="heading" value={formVal.heading} required onChange={(e)=> handleChange(e)} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Facia, waxing ..." />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Page Path</label>
                            <input name="pagePath" value={formVal.pagePath} required onChange={(e)=> handleChange(e)} type="text" className="form-control" id="exampleFormControlInput1" placeholder="/facial" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea required name="description" value={formVal.description} onChange={(e)=> handleChange(e)} className="form-control" rows={3} defaultValue={""} />
                        </div>
                        <Modal.Footer className="flex-no-wrap px-0">
                            <Button className="w-100 mr-2" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button className="w-100" type="submit" variant="primary">
                                Save
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
            {/* update service modal */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
                <Modal.Header closeButton>
                <Modal.Title>Update Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="addSecForm" onSubmit={(e)=> submitUpdate(e)}>

                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Service Icon</label>
                            <input name="icon" value={formValUpdate.icon} onChange={(e)=> handleChangeUpdate(e)} className="form-control" type="file"  />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Heading</label>
                            <input name="heading" value={formValUpdate.heading} required onChange={(e)=> handleChangeUpdate(e)} type="text" className="form-control" placeholder="Facia, waxing ..." />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Page Path</label>
                            <input name="pagePath" value={formValUpdate.pagePath} required onChange={(e)=> handleChangeUpdate(e)} type="text" className="form-control" placeholder="/facial" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea required name="description"  onChange={(e)=> handleChangeUpdate(e)} className="form-control" rows={3} defaultValue={formValUpdate.description} />
                        </div>
                        <Modal.Footer className="flex-no-wrap px-0">
                            <Button className="w-100 mr-2" variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                            <Button className="w-100" type="submit" variant="primary">
                                Save
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
            <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>      
            {pageLoader &&
            <div className="fullLoader">
                <div className="loaderCard">
                    <div className="loaderMain">
                        <div class="adminloader"></div>
                    </div>  
                </div>
            </div>
            }
        </>
    )
    
}