import { Navbar } from "./navbar"
import splender from "../assets/images/splendor.png"
import splendorCover from "../assets/images/splendorCover.png"

import blend from "../assets/images/blend.svg"
import square from "../assets/images/square.svg"
import fast from "../assets/images/fast.svg"
import treatmentIco from "../assets/images/treatmentIco.svg"
import { useEffect } from "react"
import {animate} from "../assets/js/animate"


export function LaserHair() {
    useEffect(()=> {
      animate()
    },[])
    return(
        <div class="facialPage">
            <Navbar />
            <section className="facialBanner">
          <h1 className="themeHeadingLg text-center">Laser Hair Removal </h1>
        </section>
        <section className="FacialsSec">
          <div className="container">
            <h1 className="themeHeadingMd mb-4 animatable slideInUp">Laser Hair Removal </h1>
            <p className="themeTextLg animatable slideInUp">Laser hair removal is a convenient method for the long-term reduction or removal of unwanted hair on the face and/or body. Our high-technology laser machine will promote the best, safe, and most effective laser hair removal treatments for both men and women with any skin type.</p>
            <p className="themeTextLg mb-5 animatable slideInUp">At Lumen Spa &amp; Laser, we stay up to date on the latest innovations and technologies in the safe removal of pesky and unwanted hair. We are confident these services will make your skin feel smoother and silkier than ever.</p>
            <div className="splendorMain mt-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="logoDiv">
                    <img className="img-fluid" src={splender} alt="" />
                  </div>
                  <div className="cover">
                    <img className="img-fluid" src={splendorCover} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="name"><img src={blend} alt="" /> BLEND X</div>
                  <p className="animatable slideInUp">Bilateral laser emission of Nd:YAG (1064nm) and Alexandrite (755nm) wavelengths, synchronized and adjustable proportions</p>
                  <div className="name"><img src={square} alt="" /> Square shape spot sizes</div>
                  <p className="animatable slideInUp">For uniform skin coverage, eliminating overlap and hotspots. Up to 27x27mm</p>
                  <div className="name"><img src={fast} alt="" /> Fast treatments</div>
                  <p className="animatable slideInUp">Large spot size and rapid repetition rate</p>
                  <div className="name"><img src={treatmentIco} alt="" /> Treatment versatility and skin solutions</div>
                  <p className="animatable slideInUp">Wrinkle reduction, vascular and pigmented lesions</p>
                </div>
              </div>
            </div>
            <div className="laserContent">
              <p className="themeTextLg mb-4 animatable slideInUp">With laser hair removal, you can expect:</p>
              <ul>
                <li className="themeTextLg animatable slideInUp">Smoother skin to the touch</li>
                <li className="themeTextLg animatable slideInUp">Reduction of unwanted ingrown hairs in the target area</li>
                <li className="themeTextLg animatable slideInUp">Less time wasted shaving over and over again</li>
                <li className="themeTextLg animatable slideInUp">Saves you money in the long run</li>
                <li className="themeTextLg animatable slideInUp">Elevates your self-confidence and boosts your physical appearance</li>
              </ul>
              <p className="themeTextLg my-4 animatable slideInUp">Please feel free to contact our team today with any questions you may have about our advanced laser hair removal services. We look forward to developing a custom treatment plan just for you!</p>
              <a href="https://squareup.com/appointments/book/o0uzrnt9y73e8y/LTJDWR6WY8GPG/services" target="_blank" className="themeBtnDark animatable slideInUp">BOOK NOW</a>            </div>
          </div>
        </section>
        </div>
    )
}